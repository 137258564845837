
console.info('>>> helpers loaded');

window._isSet = (obj) => {
    return obj !== null && (typeof obj !== 'undefined');
};
window._cleanStr = function (str, _case = 'lower', replaceSpaces = false) {
    if(typeof str !== 'string'){
        return null;
    }
    if (_case === 'lower') {
        str = str.toLowerCase();
    } else if (_case === 'upper') {
        str = str.toUpperCase();
    }
    if (replaceSpaces) {
        str = str.replace(/ /g, '-').replace(/'/, '-');
        str = str.replace(/-+/g, '-').replace(/^-|-$/g, '');
    }
    return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
};